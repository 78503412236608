import axios from "@/config/http.js";

// 延期申请，延期审核
export const API = {
  // 延期（申请，审核）首页
  async pageIndex(params) {
    return await axios.get("/planPostpone/list", { params });
  },
  // 新增延期申请
  async addDelay(params) {
    return await axios.post("/planPostpone", params);
  },
  // 延期申请详情
  async delayDetail(id) {
    return await axios.get(`/planPostpone/${id}`);
  },
  // 可用延期订单号列表
  async delayOrderNum() {
    return await axios.get("/planPostpone");
  },
  // 延期申请审核退回
  async delayReturn(params) {
    return await axios.post("/planPostpone/auditBack", params);
  },
  // 延期申请审核通过
  async delayPass(params) {
    return await axios.post("/planPostpone/auditPass", params);
  },
  // 延期申请审核不通过
  async delayNotPass(params) {
    return await axios.post("/planPostpone/auditRefuse", params);
  },
  // 延期申请单号生成
  async delayProductNum() {
    return await axios.post("/planPostpone/produceSn");
  },
  // 延期订单详情
  async delayOrderDetail(orderSn) {
    return await axios.get(`/planPostpone/orderPlanDetail/${orderSn}`);
  },
};
