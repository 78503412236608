<template>
  <div class="apply-modal-container">
    <a-modal
      :visible="applyModalDetail"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      :maskClosable="false"
      :width="1000"
    >
      <template slot="title">
        <span>延期{{ config.title }}</span>
        <a-divider type="vertical" />
        <a-button size="small" v-if="submitFrom.state === 0">{{
          getMenuName(stateList, submitFrom.state)
        }}</a-button>
        <a-button size="small" class="T-btn" v-if="submitFrom.state === 1">{{
          getMenuName(stateList, submitFrom.state)
        }}</a-button>
        <a-button size="small" class="T-btn-g" v-if="submitFrom.state === 2">{{
          getMenuName(stateList, submitFrom.state)
        }}</a-button>
        <a-button size="small" class="T-btn-r" v-if="submitFrom.state === 3">{{
          getMenuName(stateList, submitFrom.state)
        }}</a-button>
      </template>

      <template slot="footer">
        <a-button type="reload" @click="cancel">返回</a-button>
        <!-- <a-button
          type="reload"
          class="btnStyle"
          @click="handleReturn"
          v-if="submitFrom.state === 1"
          >退回</a-button
        > -->
        <a-button
          type="reload"
          class="btnStyle"
          @click="habdleNotPass"
          v-if="submitFrom.state === 1 && config.title === '审核'"
          >不通过</a-button
        >
        <a-button
          type="primary"
          @click="handlePass"
          v-if="submitFrom.state === 1 && config.title === '审核'"
          >通过</a-button
        >
      </template>

      <div class="layout-inline mb1">
        <label class="label">审核意见：</label>
        <a-textarea
          style="width: 80%"
          :auto-size="{ minRows: 6 }"
          :disabled="
            submitFrom.state === 0 ||
            submitFrom.state === 2 ||
            submitFrom.state === 3 ||
            config.title === '申请'
          "
          maxlength="128"
          v-model="submitFrom.auditOpinion"
        ></a-textarea>
        <span class="lenNum">已输入{{ length }}/128</span>
      </div>

      <a-divider />

      <a-form-model layout="inline" :label-col="{ span: 6 }">
        <div class="layout-inline mb1">
          <a-form-model-item label="延期申请单号" class="center">
            {{ submitFrom.postponeSn }}
          </a-form-model-item>
          <a-form-model-item class="center">
            <span slot="label">订单号</span>
            {{ submitFrom.orderSn }}
          </a-form-model-item>
        </div>
        <div class="layout-inline mb1">
          <a-form-model-item label="工厂名称" class="center">
            {{ submitFrom.factoryName }}
          </a-form-model-item>
          <a-form-model-item label="订购数量" class="center">
            {{ submitFrom.goodsNum }}
          </a-form-model-item>
        </div>
        <div class="layout-inline mb1">
          <a-form-model-item label="交货日期" style="width: 48%">
            {{ submitFrom.timeDelivery }}
          </a-form-model-item>
        </div>
        <div class="layout-inline mb1">
          <label class="label">延期原因：</label>
          {{ submitFrom.reason }}
        </div>
        <div class="layout-inline">
          <label class="label">上传附件：</label>
          <!-- <UploadFile v-model="submitFrom.applyFileList" /> -->
          <div>
            <div
              class="linkColor"
              v-for="(item, i) in submitFrom.applyFileList"
              :key="i"
            >
              <a :href="item" :download="item">{{ item }}</a>
            </div>
          </div>
        </div>
      </a-form-model>

      <a-divider />

      <div class="mb2 mt2">
        <span class="bold"></span>
        <span class="title">调整后计划情况</span>
      </div>

      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        bordered
        :scroll="{ y: 400 }"
        :pagination="false"
      >
        <template slot="productionDay" slot-scope="text, record">
          <a-switch v-model="record.productionDay" disabled />
        </template>
        <template slot="planComplete" slot-scope="text, record">
          <a-input
            v-model="record.planComplete"
            :disabled="!record.productionDay"
            style="width: 80%"
          />
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "生产日期",
    ellipsis: true,
    dataIndex: "productionData",
  },
  {
    title: "生产日",
    ellipsis: true,
    dataIndex: "productionDay",
    scopedSlots: { customRender: "productionDay" },
  },
  {
    title: "计划完成数",
    ellipsis: true,
    dataIndex: "planComplete",
  },
  {
    title: "实际完成数",
    ellipsis: true,
    dataIndex: "realityComplete",
  },
];
import mixins from "@/mixins/mixins.js";
import { getMenuName } from "@/utils/utils.js";
// import UploadFile from "@/components/uploadFile";
import { API } from "@/apix/delay.js";
import { tips } from "../../utils/tips";
export default {
  mixins: [mixins],
  props: {
    applyModalDetail: {
      type: Boolean,
      default: true,
    },
    config: {
      type: Object,
      default: () => {
        return {
          id: null,
          type: null,
        };
      },
    },
  },
  components: {
    // UploadFile,
  },
  data() {
    return {
      columns,
      data: [
        // {
        //   productionData: "2022-04-11",
        //   productionDay: true,
        //   planComplete: 200,
        //   realityComplete: 100,
        // },
        // {
        //   productionData: "2022-04-12",
        //   productionDay: false,
        //   planComplete: null,
        //   realityComplete: null,
        // },
        // {
        //   productionData: "2022-04-13",
        //   productionDay: true,
        //   planComplete: 150,
        //   realityComplete: 100,
        // },
        // {
        //   productionData: "2022-04-14",
        //   productionDay: false,
        //   planComplete: null,
        //   realityComplete: null,
        // },
        // {
        //   productionData: "2022-04-15",
        //   productionDay: true,
        //   planComplete: 150,
        //   realityComplete: 100,
        // },
      ],
      stateList: [
        // { name: "退回", value: 0 },
        { name: "审核中", value: 1 },
        { name: "通过", value: 2 },
        { name: "不通过", value: 3 },
      ],
      submitFrom: {
        postponeSn: null, // 延期申请单号
        orderSn: null, // 订单号
        factoryName: null, // 工厂名称
        timeDelivery: null, // 交货日期
        goodsNum: 2000, // 订购数量
        auditOpinion: null, // 审核意见
        reason: null, // 延期原因
        applyFileList: ["123.pdf", "afd.pdf"], // 上传附件
        state: 2, // 审核状态(0退回，1审核中，2通过，3不通过)
      },
    };
  },
  computed: {
    length() {
      if (this.submitFrom.auditOpinion === null) {
        return 0;
      }
      return (
        this.submitFrom.auditOpinion && this.submitFrom.auditOpinion.length
      );
    },
  },
  methods: {
    getMenuName,
    handleOk() {},
    // 处理退回
    // handleReturn() {
    //   if (this.submitFrom.auditOpinion === null) {
    //     this.$message.error("请输入审核意见");
    //   } else {
    //     let planPostponeDTO = {
    //       auditOpinion: this.submitFrom.auditOpinion,
    //       postponeId: this.config.id,
    //     };
    //     API.delayReturn(planPostponeDTO).then((res) => {
    //       tips(res, "退回");
    //       if (res.success) {
    //         this.cancel();
    //         this.$parent.getSourceData();
    //       }
    //     });
    //   }
    // },
    // 处理不通过
    habdleNotPass() {
      if (this.submitFrom.auditOpinion === null) {
        this.$message.error("请输入审核意见");
      } else {
        let planPostponeDTO = {
          auditOpinion: this.submitFrom.auditOpinion,
          postponeId: this.config.id,
        };
        API.delayNotPass(planPostponeDTO).then((res) => {
          tips(res, "不通过");
          if (res.success) {
            this.cancel();
            this.$parent.getSourceData();
          }
        });
      }
    },
    // 处理通过
    handlePass() {
      let planPostponeDTO = {
        auditOpinion: this.submitFrom.auditOpinion,
        postponeId: this.config.id,
      };
      API.delayPass(planPostponeDTO).then((res) => {
        tips(res, "通过");
        if (res.success) {
          this.cancel();
          this.$parent.getSourceData();
        }
      });
    },
    disabledStartDate(startValue) {
      const endValue = this.adjustEndTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() < endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    // 获取详情
    getDelayDetail(id) {
      API.delayDetail(id).then((res) => {
        for (const key in this.submitFrom) {
          this.submitFrom[key] = res.data[key];
        }
        this.data = res.data.orderPlanVOList;
      });
    },
  },
  created() {
    this.getDelayDetail(this.config.id);
  },
};
</script>

<style lang="scss" scoped>
.layout-inline {
  width: 100%;
  display: flex;
  position: relative;
  &.wrap {
    flex-wrap: wrap;
  }
}
.ant-input {
  width: 28.5rem;
}
.center {
  width: 50%;
}
.bold {
  height: 100%;
  background: #ff4600;
  margin-right: 0.5rem;
  padding: 0.5rem;
}
.mt2 {
  margin-top: 2rem;
}
.mb1 {
  margin-bottom: 1rem;
}
.mb2 {
  margin-bottom: 2rem;
}
.mt2 {
  margin-top: 2rem;
}
.label {
  width: 11.3rem;
  text-align: right;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
.linkColor {
  color: #ff4600;
}
.upload {
  color: rgba(51, 51, 51, 0.65);
  margin-left: 1rem;
  line-height: 4rem;
  font-size: 1.2rem;
}
.lenNum {
  position: absolute;
  right: 10rem;
  bottom: 1rem;
}
.T-btn {
  color: #faad14;
  margin-left: 0.7rem;
  border: 1px solid #faad14;
  padding: 2px 7px;
  &:hover {
    border: 1px solid #faad14;
    background-color: #faad14;
    color: #fff;
  }
}
.T-btn-g {
  color: #52c41a;
  margin-left: 0.7rem;
  border: 1px solid #52c41a;
  padding: 2px 7px;
  &:hover {
    border: 1px solid #52c41a;
    background-color: #52c41a;
    color: #fff;
  }
}
.T-btn-r {
  color: #ff4d4f;
  margin-left: 0.7rem;
  border: 1px solid #ff4d4f;
  padding: 2px 7px;
  &:hover {
    border: 1px solid #ff4d4f;
    background-color: #ff4d4f;
    color: #fff;
  }
}
.btnStyle {
  color: #ff4d4f;
  border: 1px solid #ff4d4f;
  &:hover {
    border: 1px solid #ff4d4f;
    background-color: #ff4d4f;
    color: #fff;
  }
}
</style>
